<template>
  <a-card title="导入企业团购信息">
    <a-form :label-col="{ span: 3 }">
      <!-- <a-form-model-item label="收款财务账户" prop="financial_account_id" class="flex mb-2">
        <a-select style="width:400px" placeholder="请选择" v-model="formData.financial_account_id">
          <a-select-option value="">请选择</a-select-option>
          <a-select-option v-for="(item, index) in financeAccountList"
               :value="item.financial_account_id" :key="index">{{item.financial_account_name}}</a-select-option>
        </a-select>
      </a-form-model-item> -->
      <a-form-model-item label="批次名" prop="stock_name" class="flex mb-2">
        <a-input style="width:400px" placeholder="起个名字（方便以后查找）" v-model="formData.stock_name" />
      </a-form-model-item>
      <a-form-model-item label="城市商户号" prop="regional_name" class="flex mb-2">
        <a-input style="width:400px" placeholder="城市" v-model="formData.regional_name" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remarks" class="flex mb-2">
        <a-input style="width:400px" placeholder="备注信息" v-model="formData.remarks" />
      </a-form-model-item>
      
      <a-form-model-item prop="import_content" label="导入数据" class="flex mb-2">
        <div class="p-0">
          <a-upload name="file" @change="selectExcel" :before-upload="handlerBeforeUpload">   
            <a-button icon="upload">选择excel</a-button>
            <a-button type="link" class="p-0 ml-4" @click="downloadTmpl">下载模板</a-button>
          </a-upload>
          <base-table v-if="tableData && tableData.length" rowKey="index"
                  :rowClassName="setRowClass"
                  :columnsData="columns"
                  :tableData="tableData">
            <template #toolbar>
              <div class="flex justify-between">
                <span class="font-bold mb-2">导入预览，共{{tableData.length}}条：</span>
              </div>
            </template>
          </base-table>
        </div>
      </a-form-model-item>

      <a-form-model-item label="领取方式" class="flex mb-2">
        <a-checkbox v-model="isNeedSignature">需要签名领取</a-checkbox>
      </a-form-model-item>

    </a-form>

    <div class="submit-class">
      <a-button style="margin-left:400px;" type="primary" @click="handlerSubmit">提交发放</a-button>
    </div>

  </a-card>
</template>

<script>

import * as XLSX from 'xlsx'
import {
  addPurchaseCards,
} from "@/api/activity/storevalue-card.js"

export default {
  components: { },
  data() {
    return {
      formData: {
        // financial_account_id: "",
        stock_name: "",
        regional_name: "",
        remarks: "",
        import_data: "",
        // is_user_signature: 1,
      },
      isNeedSignature: false,

      financeAccountList: [],

      columns: [],
      baseColumns: [
        {
          title: "行号",
          dataIndex: "index",
          align: "center",
          width: 80,
        },
        {
          title: "手机号",
          dataIndex: "A",
          align: "center",
          width: 120,
        },
        {
          title: "加密卡号",
          dataIndex: "B",
          align: "center",
          width: "40%",
        },
        {
          title: "卡号",
          dataIndex: "C",
          align: "center",
          width: "30%",
        },
        {
          title: "卡面额",
          dataIndex: "D",
          align: "center",
          width: 80,
        },
        {
          title: "优惠券码",
          dataIndex: "coupons_show",
          align: "center",
          width: "30%",
        },
      ],
      tableData: []
    }
  },
  mounted() {
    const list = this.$store.getters['authAccountList']
    this.financeAccountList = list
  },

  methods: {
    handlerBeforeUpload(file){
      console.log("handlerBeforeUpload", file)
    },

    selectExcel(e){
      if(!e.event) return
      
      let file = e.file
      if(!/.+(xlsx|xlc|xlm|xls|xlt|xlw|csv)$/.test(file.name)){
        this.$message.warning("格式错误，请重新excel文件")
        return;
      }
      this.file2Xce(file).then(result => {
        if(result.length){
          result.shift()
          let hasCoupon = false
          const coupon_keys = ["E","F","G","H","I","J"]
          result.map(item=>{
            const coupon_arr = []
            coupon_keys.map(key=>{
              if(item[key]){
                coupon_arr.push(item[key])
              }
            })
            item["coupons"] = coupon_arr
            if(coupon_arr.length){
              hasCoupon = true
              item["coupons_show"] = coupon_arr.length+"张券："+coupon_arr.join("、")
            }
          })
          if(hasCoupon){
            this.columns = this.baseColumns
          }else{
            this.columns = this.baseColumns.filter(el=>!['coupons_show'].includes(el))
          }
          this.tableData = result
        }else{
          this.tableData = []
        }
      })
    },

    file2Xce(file){
      const self = this
      return new Promise(function(resolve) {
        const reader = new FileReader();
        reader.onload = function(e) {
          const data = e.target.result;
          this.wb = XLSX.read(data, {
            type: "binary"
          });
          // 第一个sheet
          const sheetName = this.wb.SheetNames[0]
          const sheet = XLSX.utils.sheet_to_formulae(this.wb.Sheets[sheetName])
          const sheetArr = self.formatExcel(sheet)
          resolve(sheetArr);
        }
        reader.readAsBinaryString(file.originFileObj);
      });
    },
    formatExcel(list){
      let arr = []
      list.map(el=>{
        if(/^([a-zA-Z]+)(\d+)=/.test(el)){
          const row = RegExp.$1
          const index = RegExp.$2
          if(!arr[index]){
            arr[index] = {
              index: index
            }
          }
          arr[index][row] = el.replace(/^([a-zA-Z]+)(\d+)=[']?/, "")
        }
      })
      return arr.filter(item=>{
        return !!item
      })
    },
    

    async handlerSubmit() {
      if(!this.formData.stock_name){
        this.$message.warning("请填写批次名")
        return
      }
      // if(!this.formData.financial_account_id){
      //   this.$message.warning("请选择收款账户")
      //   return
      // }
      if(!this.checkBeforeSubmit()){
        return
      }
      
      let { code, data } = await addPurchaseCards({
        ...this.formData,
        is_user_signature: this.isNeedSignature ? 2 : 1,
      })
      if(code == 0){
        if(data.submit_result == "error"){
          this.$message.warning("部分卡号/加密码已存在")
          this.updateErrList(data.error_data)
        }else{
          this.formData = {}
          let self = this
          this.$success({
            title: "导入&发放成功！",
            okText: "确定",
            onOk() {
              self.$router.push({name: 'group-purchase'})
            }
          })
        } 
      }
    },

    updateErrList(errList){
      errList = errList || []
      this.tableData.map(el=>{
        let err = errList.find(err_el=>{
          return err_el["encryption_card_no"] == el["B"]
        })
        el.isErr = !!err
        return el
      })
      this.$forceUpdate()
    },

    checkBeforeSubmit(){
      const err_arr = []
      const data_arr = []
      this.tableData.map(el=>{
        if(!/^[\d]{11}$/.test(el["A"])){
          err_arr.push(el.index)
        }else if(!el["B"]){
          err_arr.push(el.index)
        }else{
          data_arr.push({
            phone: el["A"],
            encryption_card_no: el["B"],
            card_no: el["C"] || "",
            denomination : el["D"] || "",
            coupon_exchange_code : el["coupons"].join(","),
          })
        }
      })
      if(err_arr.length){
        this.$message.warning("excel的 "+err_arr.join(",")+" 行有错误")
        return false
      }
      if(!data_arr.length){
        this.$message.warning("请选择excel文件导入")
        return false
      }

      this.formData.import_data = JSON.stringify(data_arr)
      return true
    },

    setRowClass (record) {
      return record.isErr ? "row-err":""
    },

    downloadTmpl(){
      window.open("/doc/%E5%9B%A2%E8%B4%AD%E5%8D%A1%E5%AF%BC%E5%85%A5.xlsx", "_blank")
    },
  },
}
</script>

<style lang="less" scoped>
.package-class {
  position: relative;
  padding: 10px 20px 0;
  margin-top: 10px;
  margin-right: 10px;
  width: 600px;
  background-color: #f0f2f5;
  border-radius: 5px;
}
.item-class {
  display: flex;
  justify-items: center;
  align-items: center;
}
.submit-class {
  width: 100%;
  margin-top: 30px;
}

</style>
<style>
.ant-upload-list.ant-upload-list-text{
  display: none !important;
}
.row-err{
  background: #faa;
}
</style>