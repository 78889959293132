import ajax from '@/utils/ajax.js'

// 企业团购-批次列表
export function getPurchaseList(params) {
  return ajax.post('/StoredValueCardGroupBuy/getStockList',params)
}

// 企业团购-导入 某批次的卡列表
export function addPurchaseCards(params) {
  return ajax.post('/StoredValueCardGroupBuyImport/import',params)
}

// 企业团购-查看 某批次的卡列表
export function getPurchaseCardsList(params) {
  return ajax.post('/StoredValueCardGroupBuy/getStockCardList',params)
}
// 企业团购-导出 某批次的卡列表
export function exportPurchaseCardsList(params) {
  return ajax.openurl('/StoredValueCardGroupBuy/stockCardExport',params)
}



